<template>
  <Modal v-model="showModal" :mask-closable="false" width="800px">
  <div slot="header">编辑角色信息</div>
  <div class="edit-area">
    <Form class="edit-form" ref="form" :model="form" :rules="rules" label-position="top">
      <!-- <FormItem prop="customerId" label="所在组织">
        <Input type="text" maxlength="20" v-model="custName" readonly placeholder="请输入名称"></Input>
      </FormItem> -->
      <FormItem prop="name" label="角色名称">
        <Input ref="name" type="text" maxlength="20" v-model="form.name" placeholder="请输入名称">
        </Input>
      </FormItem>
      <FormItem prop="remark" label="角色说明">
        <Input ref="remark" type="textarea" maxlength="100" :rows="18" show-word-limit v-model="form.remark" placeholder="请输入角色说明">
        </Input>
      </FormItem>
      <FormItem prop="name" :label-width="0" style="margin-bottom:0">
      </FormItem>
    </Form>
    <div class="fun-container">
      <Checkbox v-model="chkAppAll" @on-change="selectAll" class="float-chk">全选</Checkbox>
      <MenuNode v-for="fun in functions" :key="`fun-${fun.code}`" :node="fun"></MenuNode>
    </div>
  </div>
    <div slot="footer">
      <Button style="margin-right: 8px" @click="cancel">取消</Button>
      <Button type="primary" :loading="loading" @click="ok">保存</Button>
    </div>
  </Modal>
</template>
<script>
import MenuNode from '../../site/cust/cust/checktree/MenuNode'
export default {
  name: 'ModalRoleEdit',
  components:{
    MenuNode,
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default(){ return {};}
    }
  },
  data () {
    return {
      showModal: this.value,
      loading: false,
      isAdd: false,
      index: -1,
      apps: [],
      chkAppAll: false,
      custName: '',
      form: {
        id: 0,
        customerId: 0,
        remark: '',
        name: '',
        chks: [],
      },
      rules: {
        name: { required: true, type: 'string', max: 20, message: '名称不能为空且长度不能超过20', trigger: 'blur' },
        remark: { required: false, type: 'string', max: 100, message: '说明长度不能超过100', trigger: 'blur' },
      },
      functions: [],
    }
  },
  computed: {
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if(this.showModal){
        this.isAdd = this.item.isAdd;
        this.custName = this.item.cust.name;
        this.chkAppAll = false;
        if(!this.isAdd){
          this.form.id = this.item.data.id;
          this.form.customerId = this.item.cust.id;
          this.form.remark = this.item.data.remark || '';
          this.form.name = this.item.data.name;
          this.form.chks = [];
          this.index = this.item.index;
        }else{
          this.form.id = 0;
          this.form.customerId = this.item.cust.id;
          this.form.name = '';
          this.form.remark = '';
          this.form.chks = [];
          this.index = -1;
        }
        this.initFunctions();
      }
    },
    showModal(val){
      this.$emit('showChanged', val);
    }
  },
  methods: {
    initFunctions: function(){
      this.$axios.post(`sys/auth/QueryRoleFuncs`, {roleId: this.form.id, custId: this.item.cust.id}).then(res => {
        if(res.code != 0){
          // this.$Message.error(res.data);
        }else{
          this.$set(this, 'functions', res.data.modules);
        }
      });
    },
    selectAll: function(){
      console.log('check all', this.chkAppAll);
      for(let fun of this.functions){
        this.setCheck(fun);
      }
    },
    setCheck: function(fun){
      if(fun.isLeaf){
        fun.checks.splice(0, fun.checks.length);
        if(this.chkAppAll){
          for(let l of fun.operations){
            fun.checks.push(l.id);
          }
        }
      }else{
        if(!fun.children)return;
        for(let sub of fun.children){
          this.setCheck(sub);
        }
      }
    },
    getChecks: function(chks, func){
      if(func.isLeaf){
        chks.push(...func.checks);
      }else{
        for(let f of func.children){
          this.getChecks(chks, f);
        }
      }
    },
    ok: async function () {
      let result = await this.$refs.form.validate();
      if(!result){
        return;
      }
      let chks = [];
      for (let fun of this.functions) {
        this.getChecks(chks, fun);
      }
      // console.log('get checks', chks);
      this.form.ops = chks;
      if (this.form.ops.length == 0) {
        this.$Message.warning('请选择角色权限');
        return;
      }
      this.loading = true;
      this.$axios.post(`sys/auth/SaveRole`, this.form).then(res => {
        this.loading = false;
        if(res.code != 0){
          // this.$Message.error(res.data);
        }else{
          this.$Message.info('保存成功');
          this.showModal = false;
          this.$emit('saved');
        }
      })
    },
    cancel () {
      this.showModal = false;
      this.$refs.form.resetFields();
    },
  }
}
</script>
<style scoped>
.edit-area{
  /* border: solid 1px rgba(216, 199, 199, 0.438); */
  display: flex;
  height: 500px;
  align-items: stretch;
}
.edit-form{
  width: 200px;
  flex: none;
}
.float-chk{
  position: absolute;
  top: 68px;
  right: 30px;
}
.form{
  height: calc(100%);
  overflow: auto;
}
.readonly{
  background: #eee
}
.fun-container{
  margin-left: 10px;
  width: 400px;
  flex: auto;
  border: solid 1px rgba(216, 199, 199, 0.438);
  border-radius: 6px;
  /* height: 100%; */
  overflow: auto;
}
.fun-list{
  display: flex;
  /* list-style: none; */
  flex-direction: column;
  margin: 8px 15px;
}
.chk-list{
  display: flex;
  flex-wrap: wrap;
  list-style: none;
}
li{
  margin: 0 5px;
}
</style>