<template>
  <div class="tree-node" :style="{paddingLeft: `${level > 0 ? 15:0}px`}">
    <div :style="{fontSize: `${20 - level * 3}px`}">
      {{ node.name }}
      <span v-if="node.remark" class="func-remark">({{ node.remark }})</span>
      <Checkbox :ref="`check_all_${node.code}`" v-if="node.checks" @on-change="checkAll($event, node)">全选</Checkbox>
      <!-- - {{ node.checks ? 'leaf' : 'mode' }} - {{ node.checks }} -->
    </div>
    <CheckboxGroup v-if="node.isLeaf" v-model="node.checks" :style="{paddingLeft: `${level > 0 ? 15:0}px`}">
      <Checkbox v-for="(item) in node.operations" :label="item.id" :key="item.id" :title="item.remark">{{item.name}}</Checkbox>
    </CheckboxGroup>
    <div v-else>
      <MenuNode v-for="mod in node.children" :key="mod.code" :node="mod" :level="level+1"></MenuNode>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import MenuNode from './MenuNode'
  export default {
    name: 'MenuNode',
    components:{
      MenuNode,
    },
    props: {
      node: {
        type: Object,
        default(){ return {};}
      },
      path: {
        type: Array,
        default(){ return [];}
      },
      level: {
        type: Number,
        default: 0,
      },
    },
    data () {
      return {
        curPath: [...this.path, this.level],
      }
    },
    computed: {
      ...mapState('group', ['selectedNode'])
    },
    mounted: function(){
    },
    methods: {
      checkAll: function(evt, node){
        node.checks.splice(0, node.checks.length);
        if(evt){
          for(let o of node.operations){
            node.checks.push(o.id);
          }
        }
      },
    }
  }
</script>
<style scoped>
.func-remark{
  margin: 0 20px 0 3px;
  color: red;
}
  .tree-node{
    width: 100%;
    /* border: solid 1px red; */
  }
  .node-item{
    height: 48px;
    display: flex;
    background-color: #041B32;
  }
  .node-item-flag{
    width: 4px;
    background-color: transparent;
  }
  .node-text{
    margin-left: 5px;
    height: 44px;
    line-height: 44px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
    user-select:none;
    flex: auto;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
  }
  .node-img{
    width: 15px;
    height: 15px;
    margin: 15px 0 0 15px;
    flex: none;
  }
  .expand-img{
    width: 12px;
    height: 8px;
    margin: 15px 15px 0 0;
    flex: none;
  }
  .node-selected{
    background: #02203C;
  }
  .node-selected-flag{
    background-color: #0CD3FC;
  }
</style>